import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { AuthState } from "@/models/utils/auth-state.interface";
import { PUBLIC } from "@/helpers/roles";

@Module({ namespaced: true, name: "auth" })
class AuthModule extends VuexModule {
  private authState: AuthState = null;
  private err: string = null;

  get token(): boolean {
    return this.authState ? this.authState.token : null;
  }

  get role(): string {
    return this.authState && this.authState.roles && this.authState.roles.length
      ? this.authState.roles[0].roleName
      : PUBLIC;
  }

  get userDescription(): string {
    if (this.authState && this.authState.user)
      return this.authState.user.userDescription;
    return "";
  }

  get userEmail(): string {
    if (this.authState && this.authState.user) return this.authState.user.email;
    return "";
  }

  get error(): string {
    return this.err ? this.err : "Email o password non corretti!";
  }

  @Mutation
  public setLoginError(state: string): void {
    this.err = state;
  }

  @Mutation
  public setLogout(): void {
    this.authState = null;
  }

  @Mutation
  public setLogin(state: AuthState): void {
    this.authState = state;
  }

  @Action
  public login(state: AuthState): void {
    this.context.commit("setLogin", state);
  }

  @Action
  public loginError(state: string): void {
    this.context.commit("setLoginError", state);
  }

  @Action
  public logout(): void {
    this.context.commit("setLogout");
  }
}

export default AuthModule;
