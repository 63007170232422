export interface Header {
  text: string;
  align?: string;
  filterable?: boolean;
  sortable?: boolean;
  value: string;
  cellType: CellType;
  groupable?: boolean;
  orderable?: boolean;
  class?: string;
  isPrimary: boolean;
  customProp?: string;
  condition?: string;
  dependencyProp?: string;
  dependencyPropValue?: unknown;
  customText?: string;
  svg?: string;
}

export enum CellType {
  DEFAULT,
  ACTION,
  LIST,
  DEPENDENCYLIST,
  SWITCH,
  DATE,
  PRICE,
  PRICEWITHPROMO,
  STATUS,
  CUSTOMERNAME,
  BOOLEAN,
  BOOLICON, // Show the icon only if the boolean value is true;
}
