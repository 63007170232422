import AuthModule from "@/store/modules/auth.module";
import CancelTokenModule from "@/store/modules/cancelToken.module";
import ErrorModule from "@/store/modules/error.module";
import SearchModule from "@/store/modules/search.module";

import store from "@/store";
import { getModule } from "vuex-module-decorators";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

const Layout = () => import("@/views/Layouts/Layout.vue");
const Dashboard = () => import("@/views/Dashboard/Dashboard.vue");
const Suppliers = () => import("@/views/Suppliers/Suppliers.vue");
const Customers = () => import("@/views/Customers/Customers.vue");
const Products = () => import("@/views/Products/Products.vue");
const ProductDetails = () =>
  import("@/views/ProductDetails/ProductDetails.vue");
const ProductPriceBooks = () =>
  import("@/views/ProductPriceBooks/ProductPriceBooks.vue");
const Preview = () => import("@/views/Preview/Preview.vue");
const Orders = () => import("@/views/OrdersManagement/Orders.vue");
const Tables = () => import("@/views/Tables/Tables.vue");
const Users = () => import("@/views/Users/Users.vue");
const Login = () => import("@/views/Login/Login.vue");
const UserProfile = () => import("@/views/UserProfile/UserProfile.vue");
const ResFavorites = () =>
  import("@/views/UserProfile/Components/Favorites/ResFavorites.vue");
const ResMyOrders = () =>
  import("@/views/UserProfile/Components/MyOrders/ResMyOrders.vue");
const ResPersonalInfo = () =>
  import("@/views/UserProfile/Components/PersonalInfo/ResPersonalInfo.vue");
const Home = () => import("@/views/Home/Home.vue");
const NewAccount = () => import("@/views/NewAccount/NewAccount.vue");
const PasswordRecovery = () =>
  import("@/views/PasswordRecovery/PasswordRecovery.vue");
const ResetPassword = () =>
  import("@/views/PasswordRecovery/ResetPassword.vue");
const Error = () => import("@/views/Error/Error.vue");

const searchModule = getModule(SearchModule, store);
const authModule = getModule(AuthModule, store);
const cancelTokenModule = getModule(CancelTokenModule, store);
const errorModule = getModule(ErrorModule, store);

import { ADMIN, OPERATOR, CLIENT, PUBLIC } from "@/helpers/roles";
import { loadLanguageAsync } from "@/i18n/config";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
    component: Layout,
    children: [
      {
        path: "/home",
        name: "home",
        component: Home,
        beforeEnter: (to, from, next) => {
          searchModule.clearResearch();
          if (authModule.role == OPERATOR || authModule.role == ADMIN) {
            router.push({ name: "dashboard" });
          } else {
            next();
          }
        },
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        beforeEnter: (to, from, next) => {
          if (authModule.role == PUBLIC) {
            authModule.logout();
            router.push({ name: "login" });
          } else if (authModule.role == CLIENT) {
            router.push({ name: "home" });
          } else {
            next();
          }
        },
      },
      {
        path: "/suppliers",
        name: "suppliers",
        component: Suppliers,
        beforeEnter: (to, from, next) => {
          if (authModule.role == PUBLIC) {
            authModule.logout();
            router.push({ name: "login" });
          } else if (authModule.role == CLIENT) {
            router.push({ name: "home" });
          } else if (authModule.role == OPERATOR) {
            router.push({ name: "dashboard" });
          } else {
            next();
          }
        },
      },
      {
        path: "/customers",
        name: "customers",
        component: Customers,
        beforeEnter: (to, from, next) => {
          if (authModule.role == PUBLIC) {
            authModule.logout();
            router.push({ name: "login" });
          } else if (authModule.role == CLIENT) {
            router.push({ name: "home" });
          } else if (authModule.role == OPERATOR) {
            router.push({ name: "dashboard" });
          } else {
            next();
          }
        },
      },
      {
        path: "/users",
        name: "users",
        component: Users,
        beforeEnter: (to, from, next) => {
          if (authModule.role == PUBLIC) {
            authModule.logout();
            router.push({ name: "login" });
          } else if (authModule.role == CLIENT) {
            router.push({ name: "home" });
          } else if (authModule.role == OPERATOR) {
            router.push({ name: "dashboard" });
          } else {
            next();
          }
        },
      },
      {
        path: "/products",
        name: "products",
        component: Products,
        beforeEnter: (to, from, next) => {
          if (authModule.role == PUBLIC) {
            authModule.logout();
            router.push({ name: "login" });
          } else if (authModule.role == CLIENT) {
            router.push({ name: "home" });
          } else {
            next();
          }
        },
      },
      {
        path: "/product-details",
        name: "details",
        component: ProductDetails,
        beforeEnter: (to, from, next) => {
          if (authModule.role == CLIENT || authModule.role == PUBLIC) {
            next();
          } else {
            authModule.logout();
            router.push({ name: "login" });
          }
        },
      },
      {
        path: "/product-price-books",
        name: "product-price-books",
        component: ProductPriceBooks,
        beforeEnter: (to, from, next) => {
          if (authModule.role == PUBLIC) {
            authModule.logout();
            router.push({ name: "login" });
          } else if (authModule.role == CLIENT) {
            router.push({ name: "home" });
          } else {
            next();
          }
        },
      },
      {
        path: "/preview",
        name: "preview",
        component: Preview,
        beforeEnter: (to, from, next) => {
          if (authModule.role == PUBLIC) {
            authModule.logout();
            router.push({ name: "login" });
          } else if (authModule.role == CLIENT) {
            router.push({ name: "home" });
          } else {
            next();
          }
        },
      },
      {
        path: "/orders",
        name: "orders",
        component: Orders,
        beforeEnter: (to, from, next) => {
          if (authModule.role == PUBLIC) {
            authModule.logout();
            router.push({ name: "login" });
          } else if (authModule.role == CLIENT) {
            router.push({ name: "home" });
          } else {
            next();
          }
        },
      },
      {
        path: "/tables",
        name: "tables",
        component: Tables,
        beforeEnter: (to, from, next) => {
          if (authModule.role == PUBLIC) {
            authModule.logout();
            router.push({ name: "login" });
          } else if (authModule.role == CLIENT) {
            router.push({ name: "home" });
          } else if (authModule.role == OPERATOR) {
            router.push({ name: "dashboard" });
          } else {
            next();
          }
        },
      },
      {
        path: "/user-profile/:tab?",
        name: "user-profile",
        component: UserProfile,
        beforeEnter: (to, from, next) => {
          if (authModule.role == PUBLIC) {
            authModule.logout();
            router.push({ name: "login" });
          } else if (authModule.role != CLIENT) {
            router.push({ name: "dashboard" });
          } else {
            next();
          }
        },
      },
      {
        path: "/favorites",
        name: "favorites",
        component: ResFavorites,
        beforeEnter: (to, from, next) => {
          if (authModule.role == ADMIN || authModule.role == OPERATOR) {
            authModule.logout();
            router.push({ name: "login" });
          } else {
            next();
          }
        },
      },
      {
        path: "/my-orders",
        name: "myorders",
        component: ResMyOrders,
        beforeEnter: (to, from, next) => {
          if (authModule.role == ADMIN || authModule.role == OPERATOR) {
            authModule.logout();
            router.push({ name: "login" });
          } else {
            next();
          }
        },
      },
      {
        path: "/personal-info",
        name: "personalinfo",
        component: ResPersonalInfo,
        beforeEnter: (to, from, next) => {
          if (authModule.role == ADMIN || authModule.role == OPERATOR) {
            authModule.logout();
            router.push({ name: "login" });
          } else {
            next();
          }
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    props: true,
  },
  {
    name: "Logout",
    path: "/logout",
    component: () => {
      if (authModule.role == CLIENT) {
        authModule.logout();
        router.push({ name: "login" });
      } else {
        authModule.logout();
        router.push({ name: "home" });
      }
    },
  },
  {
    path: "/new-account/:token?/:userInRegistrationID?",
    name: "new-account",
    component: NewAccount,
    beforeEnter: (to, from, next) => {
      if (authModule.role == PUBLIC) {
        authModule.logout();
        next();
      } else if (authModule.role == OPERATOR || authModule.role == ADMIN) {
        router.push({ name: "dashboard" });
      } else if (authModule.role == CLIENT) {
        router.push({ name: "home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/password-recovery",
    name: "password-recovery",
    component: PasswordRecovery,
    beforeEnter: (to, from, next) => {
      if (authModule.role == PUBLIC) {
        authModule.logout();
        next();
      } else if (authModule.role == OPERATOR || authModule.role == ADMIN) {
        router.push({ name: "dashboard" });
      } else if (authModule.role == CLIENT) {
        router.push({ name: "home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
    beforeEnter: (to, from, next) => {
      if (authModule.role == PUBLIC) {
        authModule.logout();
        if (!Object.keys(to.query).length) {
          router.push({ name: "login" });
        } else {
          next();
        }
      } else if (authModule.role == OPERATOR || authModule.role == ADMIN) {
        router.push({ name: "dashboard" });
      } else if (authModule.role == CLIENT) {
        router.push({ name: "home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/error",
    name: "error",
    component: Error,
    props: true,
    beforeEnter: (to, from, next) => {
      if (authModule.role == PUBLIC) {
        authModule.logout();
        next();
      } else if (authModule.role == OPERATOR || authModule.role == ADMIN) {
        router.push({ name: "dashboard" });
      } else if (authModule.role == CLIENT) {
        router.push({ name: "home" });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async function (to, from, next) {
  await loadLanguageAsync("it");
  const token = authModule.token;
  if (
    from.path === "/home" ||
    from.path === "/favorites" ||
    from.path === "/preview" ||
    from.path === "/cart"
  ) {
    cancelTokenModule.empty();
  }
  if (from.path !== to.path) {
    if (token) {
      if (to.path === "/login") {
        if (authModule.role == PUBLIC) {
          authModule.logout();
          next();
        } else if (authModule.role == CLIENT) {
          router.push({ name: "home" });
        } else if (authModule.role == OPERATOR || authModule.role == ADMIN) {
          router.push({ name: "dashboard" });
        }
      } else {
        next();
      }
    } else {
      if (to.path === "/login") {
        next();
      } else {
        if (to.path === "/home") {
          next();
        } else if (to.path === "/new-account") {
          next();
        } else if (to.path === "/password-recovery") {
          next();
        } else if (to.path === "/reset-password") {
          next();
        } else if (to.path === "/product-details") {
          next();
        } else if (to.path === "/error" && errorModule.message != null) {
          next();
        } else if (to.path === "/favorites") {
          next({ path: "/login" });
        } else if (to.path === "/my-orders") {
          next({ path: "/login" });
        } else if (to.path === "/personal-info") {
          next({ path: "/login" });
        } else {
          next({ path: "/home" });
        }
      }
    }
  }
});
export default router;
