import { TokenType } from "@/models/utils/check-token-request.interface";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "error" })
class ErrorModule extends VuexModule {
  private error = null;
  private errorType = null;
  private tokenType = null;

  get message(): string {
    return this.error != null ? this.error : null;
  }
  get type(): string {
    return this.errorType != null ? this.errorType : null;
  }
  get token(): string {
    return this.tokenType != null ? this.tokenType : null;
  }

  @Mutation
  public setError(state: string): void {
    this.error = state;
  }
  @Action
  public getError(state: string): void {
    this.context.commit("setError", state);
  }

  @Mutation
  public setType(state: number): void {
    this.errorType = state;
  }
  @Action
  public getType(state: number): void {
    this.context.commit("setType", state);
  }

  @Mutation
  public setTokenType(state: TokenType): void {
    this.tokenType = state;
  }
  @Action
  public getTokenType(state: TokenType): void {
    this.context.commit("setTokenType", state);
  }
}

export default ErrorModule;
