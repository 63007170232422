import { CartProduct } from "@/models/entities/cart-product.interface";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true, name: "cart" })
class CartModule extends VuexModule {
  private _showCart = false;
  private _showOrderCompleted = false;
  private carts = [] as CartProduct[];

  get products(): CartProduct[] {
    return this.carts;
  }

  get totals(): CartProduct[] {
    return this.carts.flatMap((el) => {
      el.total = (el.quantity * el.price).toFixed(2).toNumber();
      if (el.isInPromo) {
        el.totalInPromo = (el.quantity * el.priceInPromo).toFixed(2).toNumber();
      }
      return el;
    });
  }

  get showCart(): boolean {
    return this._showCart;
  }

  get showOrderCompleted(): boolean {
    return this._showOrderCompleted;
  }

  @Mutation
  public setShowCart(): void {
    this._showCart = !this._showCart;
  }

  @Mutation
  public setOrderCompleted(value: boolean): void {
    this._showOrderCompleted = value;
  }

  @Mutation
  public setCart(state: CartProduct[]): void {
    this.carts = state;
  }

  @Action
  public getCart(state: CartProduct[]): void {
    this.context.commit("setCart", state);
  }

  @Action
  public changeOrderCompleted(value: boolean): void {
    this.context.commit("setOrderCompleted", value);
  }

  @Mutation
  private close(): void {
    this._showCart = false;
  }

  @Mutation
  public setEmptyCart(): void {
    this.carts = [];
  }
  @Action
  public empty(): void {
    this.context.commit("setEmptyCart");
  }

  @Action
  public emptyMissingProducts(): void {
    this.context.commit("setEmptyMissingProducts");
  }

  @Mutation
  public setNewQuantity(state: CartProduct): void {
    const c = this.carts.find(
      (c) =>
        c.productPriceBookID == state.productPriceBookID &&
        c.measureUnitTypeID == state.measureUnitTypeID
    );
    if (c) {
      c.quantity = (state.quantity - 1).toString().toNumber();
    }
  }
  @Action
  public edit(state: CartProduct): void {
    this.context.commit("setNewQuantity", state);
  }

  @Mutation
  public deleteProduct(state: CartProduct): void {
    const c = this.carts.findIndex(
      (c) =>
        c.productPriceBookID == state.productPriceBookID &&
        c.measureUnitTypeID == state.measureUnitTypeID
    );
    if (c > -1) {
      this.carts.splice(c, 1);
    }
  }
  @Action
  public delete(state: CartProduct): void {
    this.context.commit("deleteProduct", state);
  }

  @Mutation
  public addProduct(state: CartProduct): void {
    const c = this.carts.findIndex(
      (c) =>
        c.productPriceBookID == state.productPriceBookID &&
        c.measureUnitTypeID == state.measureUnitTypeID
    );
    if (c == -1) {
      this.carts.push(state);
    }
  }
  @Action
  public add(state: CartProduct): void {
    this.context.commit("addProduct", state);
  }

  @Mutation
  public insertOrUpdateProduct(state: CartProduct): void {
    const c = this.carts.find(
      (c) =>
        c.productPriceBookID == state.productPriceBookID &&
        c.measureUnitTypeID == state.measureUnitTypeID
    );
    if (c) {
      c.quantity = c.quantity + state.quantity;
    } else {
      this.carts.push(state);
    }
  }

  @Action
  public insertOrUpdate(state: CartProduct): void {
    this.context.commit("insertOrUpdateProduct", state);
  }

  @Action
  public toggleCart(): void {
    this.context.commit("setShowCart");
  }

  @Action
  public closeCart(): void {
    this.context.commit("close");
  }
}
export default CartModule;
