import { VuelandoTheme } from "vuelando/dist/types/helpers/theme";

export const lightTheme: VuelandoTheme = {
  primary: {
    container: "#B6E0F3",
    component: "#B6E0F3",
    icon: "#65ADCE",
    background: "#4998BC",
  },
  onPrimary: {
    containerText: "#015074",
    containerLow: "#0A87C0",
    icon: "#B6E0F3",
    backgroundText: "#EEF8FC",
  },
  primaryOpacity: {
    opacity60: "#B6E0F399",
    opacity40: "#B6E0F366",
    opacity20: "#B6E0F333",
    opacity10: "#B6E0F31A",
    opacity5: "#B6E0F30D",
  },
  neutralHC: {
    high: "#050000",
    medium: "#1F1C1B",
    low: "#4F4B49",
  },
  onNeutralHC: {
    text: "#FFFFFF",
    icon: "#F8F3F1",
    border: "#BBB7B5",
  },
  hcOpacity: {
    opacity80: "#1F1C1BCC",
    opacity60: "#1F1C1B99",
    opacity40: "#1F1C1B66",
    opacity20: "#1F1C1B33",
    opacity10: "#1F1C1B1A",
    opacity5: "#1F1C1B0D",
  },
  neutralLC: {
    high: "#FFFFFF",
    medium: "#D9D5D3",
    low: "#9F9B99",
  },
  onNeutralLC: {
    text: "#050000",
    icon: "#4F4B49",
    border: "#837F7D",
  },
  lcOpacity: {
    opacity80: "#FFFFFFCC",
    opacity60: "#FFFFFF99",
    opacity40: "#FFFFFF66",
    opacity20: "#FFFFFF33",
    opacity10: "#FFFFFF1A",
    opacity5: "#FFFFFF0D",
  },
  background: {
    high: "#FFFFFF",
    low: "#F8F6F6",
  },
  onBackground: {
    low: "#686463",
    medium: "#4F4B49",
    high: "#1F1C1B",
    primaryHigh: "#3786AA",
    primaryLow: "#B6E0F3",
  },
  success: {
    containerHigh: "#CDFAE1",
    containerLow: "#CDFAE1",
    border: "#8FEFBA",
  },
  onSuccess: {
    textHigh: "#033419",
    textLow: "#0A572C",
    icon: "#27C16C",
  },
  warning: {
    containerHigh: "#FFEDCA",
    containerLow: "#FFEDCA",
    border: "#FEDC99",
  },
  onWarning: {
    textHigh: "#3E1803",
    textLow: "#8C5400",
    icon: "#FAB754",
  },
  error: {
    containerHigh: "#FFD9D9",
    containerLow: "#FFD9D9",
    border: "#FFB5BE",
  },
  onError: {
    textHigh: "#480509",
    textLow: "#761016",
    icon: "#EC5E78",
  },
  elevationE1: {
    background: "",
    shadow: "0px 1px 1px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.13)",
  },
  elevationE2: {
    background: "",
    shadow: "0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  elevationE3: {
    background: "",
    shadow: "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.13)",
  },
  elevationE4: {
    background: "",
    shadow: "0px 1px 3px rgba(0, 0, 0, 0.11), 0px 6px 14px rgba(0, 0, 0, 0.13)",
  },
  elevationE5: {
    background: "",
    shadow:
      "0px 2px 7px rgba(0, 0, 0, 0.18), 0px 12px 28px rgba(0, 0, 0, 0.22)",
  },
  elevationE6: {
    background: "",
    shadow:
      "0px 5px 15px rgba(0, 0, 0, 0.18), 0px 26px 58px rgba(0, 0, 0, 0.22)",
  },
  focus: "#E36F95",
  borderRadius: "4px",
};

// import { VuelandoTheme } from "vuelando/dist/types/helpers/theme";

// export const lightTheme: VuelandoTheme = {
//   primary: {
//     container: "#B6E0F3",
//     component: "#8AC4DF",
//     icon: "#65ADCE",
//     background: "#4998BC",
//   },
//   onPrimary: {
//     containerText: "#005E88",
//     containerLow: "#4998BC",
//     icon: "#B6E0F3",
//     backgroundText: "#EEF8FC",
//   },
//   primaryOpacity: {
//     opacity60: "#B6E0F399",
//     opacity40: "#B6E0F366",
//     opacity20: "#B6E0F333",
//     opacity10: "#B6E0F31A",
//     opacity5: "#B6E0F30D",
//   },
//   neutralHC: {
//     high: "#050000",
//     medium: "#1F1C1B",
//     low: "#4F4B49",
//   },
//   onNeutralHC: {
//     text: "#FFFFFF",
//     icon: "#F8F3F1",
//     border: "#BBB7B5",
//   },
//   hcOpacity: {
//     opacity80: "#1F1C1BCC",
//     opacity60: "#1F1C1B99",
//     opacity40: "#1F1C1B66",
//     opacity20: "#1F1C1B33",
//     opacity10: "#1F1C1B1A",
//     opacity5: "#1F1C1B0D",
//   },
//   neutralLC: {
//     high: "#FFFFFF",
//     medium: "#D9D5D3",
//     low: "#9F9B99",
//   },
//   onNeutralLC: {
//     text: "#050000",
//     icon: "#4F4B49",
//     border: "#837F7D",
//   },
//   lcOpacity: {
//     opacity80: "#FFFFFFCC",
//     opacity60: "#FFFFFF99",
//     opacity40: "#FFFFFF66",
//     opacity20: "#FFFFFF33",
//     opacity10: "#FFFFFF1A",
//     opacity5: "#FFFFFF0D",
//   },
//   background: {
//     high: "#FFFFFF",
//     low: "#F8F6F6",
//   },
//   onBackground: {
//     low: "#686463",
//     medium: "#4F4B49",
//     high: "#1F1C1B",
//     primaryHigh: "#3A322E",
//     primaryLow: "#E5DEDB",
//   },
//   success: {
//     containerHigh: "#CDFAE1",
//     containerLow: "#CDFAE1",
//     border: "#8FEFBA",
//   },
//   onSuccess: {
//     textHigh: "#033419",
//     textLow: "#0A572C",
//     icon: "#27C16C",
//   },
//   warning: {
//     containerHigh: "#FFEDCA",
//     containerLow: "#FFEDCA",
//     border: "#FEDC99",
//   },
//   onWarning: {
//     textHigh: "#3E1803",
//     textLow: "#8C5400",
//     icon: "#FAB754",
//   },
//   error: {
//     containerHigh: "#FFD9D9",
//     containerLow: "#FFD9D9",
//     border: "#FFB5BE",
//   },
//   onError: {
//     textHigh: "#480509",
//     textLow: "#761016",
//     icon: "#EC5E78",
//   },
//   elevationE1: {
//     background: "",
//     shadow: "0px 1px 1px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.13)",
//   },
//   elevationE2: {
//     background: "",
//     shadow: "0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 4px rgba(0, 0, 0, 0.1)",
//   },
//   elevationE3: {
//     background: "",
//     shadow: "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.13)",
//   },
//   elevationE4: {
//     background: "",
//     shadow: "0px 1px 3px rgba(0, 0, 0, 0.11), 0px 6px 14px rgba(0, 0, 0, 0.13)",
//   },
//   elevationE5: {
//     background: "",
//     shadow:
//       "0px 2px 7px rgba(0, 0, 0, 0.18), 0px 12px 28px rgba(0, 0, 0, 0.22)",
//   },
//   elevationE6: {
//     background: "",
//     shadow:
//       "0px 5px 15px rgba(0, 0, 0, 0.18), 0px 26px 58px rgba(0, 0, 0, 0.22)",
//   },
//   focus: "#E36F95",
// };
