import { CancelTokenSource } from "axios";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "cancelToken" })
class CancelTokenModule extends VuexModule {
  private cancelTokens = [] as CancelTokenSource[];

  @Mutation
  public setEmpty(): void {
    this.cancelTokens.forEach((el) => el.cancel());
    this.cancelTokens = [];
  }

  @Mutation
  public addToken(state: CancelTokenSource): void {
    this.cancelTokens.push(state);
  }

  @Action
  public empty(): void {
    this.context.commit("setEmpty");
  }

  @Action
  public add(state: CancelTokenSource): void {
    this.context.commit("addToken", state);
  }
}

export default CancelTokenModule;
