import { ProductsCard } from "@/models/entities/products-card.interface";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true, name: "productdetails" })
class ProductDetailsModule extends VuexModule {
  private _product: ProductsCard = null;

  get product(): ProductsCard {
    return this._product;
  }

  @Mutation
  public setProduct(product: ProductsCard): void {
    this._product = product;
  }

  @Action
  public changeProduct(product: ProductsCard): void {
    this.context.commit("setProduct", product);
  }
}

export default ProductDetailsModule;
