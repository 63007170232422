import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import addYears from "date-fns/addYears";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import differenceInDays from "date-fns/differenceInDays";
import endOfMonth from "date-fns/endOfMonth";
import endOfWeek from "date-fns/endOfWeek";
import endOfYear from "date-fns/endOfYear";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isSameDay from "date-fns/isSameDay";
import isSameMonth from "date-fns/isSameMonth";
import isSameYear from "date-fns/isSameYear";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import startOfYear from "date-fns/startOfYear";
import it from "date-fns/locale/it";
import { helpers } from "./methods";

export {};

declare global {
  // https://date-fns.org/v2.23.0/docs/format
  export interface Date {
    toFormat(stringFormat?: string): string;
    startOfYear(): Date;
    endOfYear(): Date;
    startOfMonth(): Date;
    endOfMonth(): Date;
    startOfWeek(): Date;
    endOfWeek(): Date;
    range(date: Date): Date[];
    range(number: number): Date[];
    formattedRange(
      date: Date,
      stringFormat?: string,
      locale?: Locale
    ): string[];
    formattedRange(
      number: number,
      stringFormat?: string,
      locale?: Locale
    ): string[];
    dayName(locale?: Locale): string;
    shortDayName(locale?: Locale): string;
    monthName(locale?: Locale): string;
    shortMonthName(locale?: Locale): string;
    isItalianFestivity(): boolean;
    isItalianFestivityOrSunday(): boolean;
    isSameDate(date: Date): boolean;
    isSameDay(date: Date): boolean;
    isSameMonth(date: Date): boolean;
    isSameMonthAndYear(date: Date): boolean;
    isSameYear(date: Date): boolean;
    isAfter(date: Date): boolean;
    isBefore(date: Date): boolean;
    differenceInDays(date: Date): number;
    differenceInCalendarDays(date: Date): number;
    addDays(number: number): Date;
    addMonths(number: number): Date;
    addYears(number: number): Date;
    isLeapYear(): boolean;
  }
  export interface String {
    toNumber(): number;
  }

  export interface Number {
    isEven(): boolean;
  }
}

Date.prototype.toFormat = function (
  this: Date,
  stringFormat = "yyyy-MM-dd",
  locale = it
): string {
  return format(this, stringFormat, { locale: locale });
};

Date.prototype.startOfYear = function (this: Date): Date {
  return startOfYear(this);
};

Date.prototype.endOfYear = function (this: Date): Date {
  return endOfYear(this);
};

Date.prototype.startOfMonth = function (this: Date): Date {
  return startOfMonth(this);
};

Date.prototype.endOfMonth = function (this: Date): Date {
  return endOfMonth(this);
};

Date.prototype.startOfWeek = function (this: Date): Date {
  return startOfWeek(this, { weekStartsOn: 1 });
};

Date.prototype.endOfWeek = function (this: Date): Date {
  return endOfWeek(this, { weekStartsOn: 1 });
};

Date.prototype.range = function (this: Date, next: Date | number): Date[] {
  let date;
  if (typeof next === "number") {
    date = this.addDays(next);
  } else {
    date = next;
  }

  return helpers.date.getRange(this, date);
};

Date.prototype.formattedRange = function (
  this: Date,
  next: Date | number,
  stringFormat = "yyyy-MM-dd",
  locale = it
): string[] {
  let date;
  if (typeof next === "number") {
    date = this.addDays(next);
  } else {
    date = next;
  }

  return helpers.date.getFormattedRange(this, date, stringFormat, locale);
};

Date.prototype.dayName = function (this: Date, locale = it): string {
  return helpers.date.getDayName(this, locale);
};

Date.prototype.shortDayName = function (this: Date, locale = it): string {
  return helpers.date.getShortDayName(this, locale);
};

Date.prototype.monthName = function (this: Date, locale = it): string {
  return helpers.date.getMonthName(this, locale);
};

Date.prototype.shortMonthName = function (this: Date, locale = it): string {
  return helpers.date.getShortMonthName(this, locale);
};

Date.prototype.isItalianFestivity = function (this: Date): boolean {
  const month = this.getMonth() + 1;
  const day = this.getDate();

  if (month == 1 && (day == 1 || day == 6)) return true; // Capodanno ed Epifania
  if (month == 4 && day == 25) return true; // Giorno della liberazione
  if (month == 5 && day == 1) return true; // Festa del lavoro
  if (month == 6 && day == 2) return true; // Festa della Repubblica
  if (month == 8 && day == 15) return true; // Assunzione
  if (month == 11 && day == 1) return true; // Ognisanti
  if (month == 12 && (day == 8 || day == 25 || day == 26)) return true; // Immacolata Concezione, Natale, S.Stefano

  const easter = helpers.date.getEasterDate(this.getFullYear());
  if (isSameDay(easter, this) || isSameDay(easter.addDays(1), this)) {
    // Lunedi dell'Angelo
    return true;
  }

  return false;
};

Date.prototype.isItalianFestivityOrSunday = function (this: Date): boolean {
  return this.isItalianFestivity() || this.getDay() === 0;
};

Date.prototype.isSameDay = function (this: Date, date: Date): boolean {
  return isSameDay(this, date);
};

Date.prototype.isSameDate = function (this: Date, date: Date): boolean {
  return this.getDate() === date.getDate();
};

Date.prototype.isSameMonth = function (this: Date, date: Date): boolean {
  return this.getMonth() === date.getMonth();
};

Date.prototype.isSameMonthAndYear = function (this: Date, date: Date): boolean {
  return isSameMonth(this, date);
};

Date.prototype.isSameYear = function (this: Date, date: Date): boolean {
  return isSameYear(this, date);
};

Date.prototype.isBefore = function (this: Date, date: Date): boolean {
  return isBefore(this, date);
};

Date.prototype.isAfter = function (this: Date, date: Date): boolean {
  return isAfter(this, date);
};

Date.prototype.differenceInDays = function (this: Date, date: Date): number {
  if (this.isAfter(date)) {
    return differenceInDays(this, date);
  } else {
    return differenceInDays(date, this);
  }
};

Date.prototype.differenceInCalendarDays = function (
  this: Date,
  date: Date
): number {
  if (this.isAfter(date)) {
    return differenceInCalendarDays(this, date);
  } else {
    return differenceInCalendarDays(date, this);
  }
};

Date.prototype.addDays = function (this: Date, number: number): Date {
  return addDays(this, number);
};

Date.prototype.addMonths = function (this: Date, number: number): Date {
  return addMonths(this, number);
};

Date.prototype.addYears = function (this: Date, number: number): Date {
  return addYears(this, number);
};

Date.prototype.isLeapYear = function (this: Date): boolean {
  return helpers.date.isLeapYear(this);
};

String.prototype.toNumber = function (this: string): number {
  const isNumber = helpers.number.isNumber(this);

  if (isNumber) {
    return Number(this);
  } else {
    return null;
  }
};

Number.prototype.isEven = function (this: number): boolean {
  return helpers.number.isNumber(this) && this % 2 === 0;
};
