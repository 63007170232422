import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "email" })
class EmailModule extends VuexModule {
  private sended = false;

  get status(): boolean {
    return this.sended;
  }

  @Mutation
  public setTrue(): void {
    this.sended = true;
  }

  @Mutation
  public setFalse(): void {
    this.sended = false;
  }

  @Action
  public show(): void {
    this.context.commit("setTrue");
  }
  @Action
  public hide(): void {
    this.context.commit("setFalse");
  }
}

export default EmailModule;
