import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: "#a6d1ea",
          lighten1: "#d4e8f4",
          lighten2: "#e4f2f9",
          darken1: "#3596cf",
          darken2: "#0779ca",
        },
        secondary: {
          base: "#BDBDBD",
          lighten1: "#e3e3e3",
          lighten2: "#EEEEEE",
          darken1: "#9f9f9f",
          darken2: "#6a6a6a",
        },
        accent: {
          base: "#fb8c00",
          lighten1: "#ffc378",
          lighten2: "#fedc99",
          darken1: "#d58000",
          darken2: "#bb6800",
        },
        error: {
          base: "#ff5252",
          lighten1: "#f88",
          lighten2: "#ffb5be",
          darken1: "#d1183a",
          darken2: "#920000",
        },
        success: {
          base: "#53c74a",
          lighten1: "#a9e3a4",
          lighten2: "#d8ecd4",
          darken1: "#3aa633",
          darken2: "#2a7624",
        },
        background: {
          base: "#EEEEEE",
        },
        brown: {
          base: "#836c61",
        },
        isInPromo: {
          base: "#ED8501",
        },
      },
    },
  },
});
