import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ProductsCard } from "@/models/entities/products-card.interface";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import qs from "qs";

@Module({ namespaced: true, name: "search" })
class SearchModule extends VuexModule {
  private srcString: string = null;
  private categoryId: number = null;
  private products: ProductsCard[] = [];

  get src(): string {
    return this.srcString;
  }

  get category(): number {
    return this.categoryId;
  }

  get productsCard(): ProductsCard[] {
    return this.products;
  }

  @Mutation
  private setData(data: {
    categoryID: number | null;
    search: string | null;
    products: ProductsCard[];
  }): void {
    this.categoryId = data.categoryID;
    this.srcString = data.search;
    this.products = data.products;
  }

  @Mutation
  private clearResearchData(): void {
    this.srcString = null;
    this.categoryId = null;
  }

  @Action
  public async retrieveProducts(
    axiosConfig: AxiosRequestConfig = null
  ): Promise<void> {
    const categoryID = axiosConfig.params["categoryID"];
    const search = axiosConfig.params["search"];
    axiosConfig.paramsSerializer = (params) => {
      return qs.stringify(params, {
        arrayFormat: "repeat",
        skipNulls: true,
      });
    };
    const result = await axios.get<AxiosResponse<Promise<ProductsCard[]>>>(
      `${process.env.VUE_APP_API_STANDARD}product/view`,
      axiosConfig
    );
    this.context.commit("setData", {
      categoryID: categoryID,
      search: search,
      products: result.data,
    });
  }

  @Action
  public clearResearch(): void {
    this.context.commit("clearResearchData");
  }
}

export default SearchModule;
