
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "./store";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
const overlayModule = getModule(OverlayModule, store);
const snackBarModule = getModule(SnackbarModule, store);

@Component
export default class App extends Vue {
  get overlay(): boolean {
    return overlayModule.status;
  }

  get snackbar(): boolean {
    return snackBarModule.status;
  }

  get message(): string {
    return snackBarModule.message;
  }

  get type(): string {
    return snackBarModule.type;
  }

  get icon(): string {
    return this.type == "warning"
      ? "mdi-information"
      : this.type == "error"
      ? "mdi-alert"
      : this.type == "success"
      ? "mdi-check-circle"
      : "";
  }
}
