import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { i18n } from "@/i18n/config";
import { CellType } from "@/models/utils/header.interface";
import "@/helpers/extensions";
import {
  ZBtn,
  ZSelect,
  ZTextField,
  ZDatePicker,
  ZDataTable,
  ZFab,
  ZListItem,
  ZCard,
  ZTag,
  ZAutocomplete,
  ZAppBar,
  ZSnackbar,
  ZAvatar,
  ZUpload,
  ZFileInput,
  ZBooleanCol,
  ZTextArea,
  ZSearchBar,
  ZBackBtn,
  setupTheme,
  ZToggle,
  ZCheckbox,
  ZLoginField,
  ZDialog,
  ZAlert,
  ZRadioGroup,
  ZRadio,
  ZExternalLink,
  ZWizard,
  ZWizardBlock,
  ZButtonGroup,
  ZBadgeNumber,
  ZUserMenu,
  ZDateRangePicker,
} from "vuelando";
import { lightTheme } from "./helpers/themes";

Vue.config.productionTip = false;
Vue.component("z-select", ZSelect);
Vue.component("z-text-field", ZTextField);
Vue.component("z-search-bar", ZSearchBar);
Vue.component("z-date-picker", ZDatePicker);
Vue.component("z-data-table", ZDataTable);
Vue.component("z-list-item", ZListItem);
Vue.component("z-tag", ZTag);
Vue.component("z-fab", ZFab);
Vue.component("z-autocomplete", ZAutocomplete);
Vue.component("z-app-bar", ZAppBar);
Vue.component("z-btn", ZBtn);
Vue.component("z-snackbar", ZSnackbar);
Vue.component("z-avatar", ZAvatar);
Vue.component("z-upload", ZUpload);
Vue.component("z-file-input", ZFileInput);
Vue.component("z-boolean-col", ZBooleanCol);
Vue.component("z-textarea", ZTextArea);
Vue.component("z-toggle", ZToggle);
Vue.component("z-login-field", ZLoginField);
Vue.component("z-dialog", ZDialog);
Vue.component("z-alert", ZAlert);
Vue.component("z-wizard", ZWizard);
Vue.component("z-external-link", ZExternalLink);
Vue.component("z-wizard-block", ZWizardBlock);
Vue.component("z-card", ZCard);
Vue.component("z-radio-group", ZRadioGroup);
Vue.component("z-radio", ZRadio);
Vue.component("z-button-group", ZButtonGroup);
Vue.component("z-checkbox", ZCheckbox);
Vue.component("z-back-btn", ZBackBtn);
Vue.component("z-user-menu", ZUserMenu);
Vue.component("z-date-range-picker", ZDateRangePicker);
Vue.component("z-badge-number", ZBadgeNumber);

setupTheme(lightTheme, false);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

const LLDataTable = () =>
  import(/* webpackChunkName: "LLDataTable" */ "@/components/LLDataTable.vue");
const LLDialog = () =>
  import(/* webpackChunkName: "LLDialog" */ "@/components/LLDialog.vue");
const LLProductCard = () =>
  import(
    /* webpackChunkName: "LLProductCard" */ "@/components/LLProductCard.vue"
  );
const LLCategoriesSelect = () =>
  import(
    /* webpackChunkName: "LLCategoriesSelect" */ "@/components/LLCategoriesSelect.vue"
  );
const OrdersFilters = () =>
  import(
    /* webpackChunkName: "LLProductCard" */ "@/components/OrdersFilters.vue"
  );
const OrderDetails = () =>
  import(
    /* webpackChunkName: "LLProductCard" */ "@/components/OrderDetails.vue"
  );

Vue.component("ll-data-table", LLDataTable);
Vue.component("ll-dialog", LLDialog);
Vue.component("ll-product-card", LLProductCard);
Vue.component("ll-categories-select", LLCategoriesSelect);
Vue.component("orders-filters", OrdersFilters);
Vue.component("order-details", OrderDetails);

Vue.prototype.CellType = CellType;
Vue.config.productionTip = false;
