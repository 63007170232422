import { format, isLeapYear } from "date-fns";
import { it } from "date-fns/locale";

export const helpers = {
  date: {
    // https://gitlab.com/Zelando/zac/zaccloud/libs/zlibhelper/-/blob/master/DateTimeHelper.cs
    // https://date-fns.org/v2.23.0/docs/format
    getRange(startDate: Date, endDate: Date): Date[] {
      const days = startDate.differenceInDays(endDate);

      return [...Array(days + 1).keys()].map((i) => startDate.addDays(i));
    },
    getFormattedRange(
      startDate: Date,
      endDate: Date,
      stringFormat = "yyyy-MM-dd",
      locale = it
    ): string[] {
      const days = startDate.differenceInDays(endDate);

      return [...Array(days + 1).keys()].map((i) =>
        format(startDate.addDays(i), stringFormat, { locale: locale })
      );
    },
    getDayName(date: Date | number, locale = it): string {
      let realDate;
      if (typeof date === "number") {
        realDate = new Date().startOfWeek().addDays(date);
      } else {
        realDate = date;
      }
      return format(realDate, "EEEE", { locale: locale });
    },
    getShortDayName(date: Date | number, locale = it): string {
      let realDate;
      if (typeof date === "number") {
        realDate = new Date().startOfWeek().addDays(date);
      } else {
        realDate = date;
      }
      return format(realDate, "E", { locale: locale });
    },
    getMonthName(date: Date | number, locale = it): string {
      let realDate;
      if (typeof date === "number") {
        realDate = new Date().startOfYear().addMonths(date);
      } else {
        realDate = date;
      }
      return format(realDate, "LLLL", { locale: locale });
    },
    getShortMonthName(date: Date | number, locale = it): string {
      let realDate;
      if (typeof date === "number") {
        realDate = new Date().startOfYear().addMonths(date);
      } else {
        realDate = date;
      }
      return format(realDate, "LLL", { locale: locale });
    },
    getEasterDate(year = new Date().getFullYear()): Date {
      if (year < 325) {
        throw new RangeError("Cannot calculate Easter dates before 325 AD.");
      }

      function mod(a: number, b: number) {
        return a % b;
      }

      function div(a: number, b: number) {
        const q = a / b;
        if (q < 0) {
          throw new Error("Unexpected negative q");
        }
        return Math.floor(q);
      }

      const y = year,
        skipMarchDays = 21,
        a = mod(y, 19),
        b = div(y, 100),
        c = mod(y, 100),
        d = div(b, 4),
        e = mod(b, 4),
        f = div(b + 8, 25),
        g = div(b - f + 1, 3),
        h = mod(19 * a + b - d - g + 15, 30),
        i = div(c, 4),
        k = mod(c, 4),
        l = mod(32 + 2 * e + 2 * i - h - k, 7),
        m = div(a + 11 * h + 22 * l, 451),
        t = h + l - 7 * m + skipMarchDays,
        n = div(t, 31) + 3,
        p = mod(t, 31);

      return new Date(year, n - 1, p + 1);
    },
    isLeapYear(date: Date | number = new Date().getFullYear()): boolean {
      let realDate;
      if (typeof date === "number") {
        realDate = new Date(date, 1, 1);
      } else {
        realDate = date;
      }

      return isLeapYear(realDate);
    },
  },
  number: {
    isNumber(value: unknown): boolean {
      return (
        !isNaN(Number(value)) &&
        isFinite(Number(value)) &&
        typeof Number(value) === "number"
      );
    },
  },
  object: {
    toFormData(obj: unknown): FormData {
      const formData: FormData = new FormData();

      for (const [key, value] of Object.entries(obj)) {
        if (Array.isArray(value)) {
          for (const val of value) {
            formData.append(key, val);
          }
        } else {
          formData.append(key, value ? value : "");
        }
      }

      return formData;
    },
  },
};
