import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import Cookies from "js-cookie";
import AuthModule from "@/store/modules/auth.module";
import CancelTokenModule from "./modules/cancelToken.module";
import CartModule from "@/store/modules/cart.module";
import EmailModule from "./modules/email.module";
import ErrorModule from "./modules/error.module";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import SearchModule from "./modules/search.module";
import ProductDetails from "./modules/productdetails.module";

Vue.use(Vuex);

const vuexAuth = new VuexPersistence({
  key: "lecco-latte-auth",
  restoreState: (key) => {
    return JSON.parse(Cookies.get(key) ? Cookies.get(key) : null);
  },
  saveState: (key, state) => {
    Cookies.set(key, JSON.stringify(state), {
      expires: 30,
    } as Cookies.CookieAttributes);
  },
  modules: ["auth"],
});

const store = new Vuex.Store({
  modules: {
    auth: AuthModule,
    cancelToken: CancelTokenModule,
    cart: CartModule,
    email: EmailModule,
    error: ErrorModule,
    overlay: OverlayModule,
    snackbar: SnackbarModule,
    search: SearchModule,
    productdetails: ProductDetails,
  },
  plugins: [vuexAuth.plugin],
});

export default store;
